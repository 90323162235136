<template>
  <div>
    <Tooltip :isClick="true" align="right" ref="tooltip" @open="closeHeaderMenu">
      <template v-slot:trigger>
        <SvgIcon name="user" title="Account" />
      </template>
      <template v-slot:content>
        <AccountLinks v-show="isLoggedIn" />
        <ul v-show="!isLoggedIn">
          <li><button @click="openHeaderMenuExpand('login'); closeTooltip($event);">Log In</button></li>
          <li><button @click="openHeaderMenuExpand('register'); closeTooltip($event);">Sign Up For Rewards</button></li>
        </ul>
      </template>
    </Tooltip>
    <button @click="openHeaderMenuExpand('mobile-account-nav')">
      <SvgIcon name="user" title="Account" />
    </button>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  import Tooltip from '@/components/global/Tooltip';
  import SvgIcon from '@/components/global/SvgIcon';
  import AccountLinks from '@/components/global/AccountLinks';

  export default {
    name: 'Account',

    components: {
      Tooltip,
      SvgIcon,
      AccountLinks
    },

    computed: {
      ...mapGetters([
        'isLoggedIn'
      ])
    },

    methods: {
      ...mapActions([
        'openHeaderMenuExpand',
        'closeHeaderMenu'
      ]),
      closeTooltip($event) {
        this.$refs.tooltip.closeTooltip($event);
      }
    }
  }
</script>
