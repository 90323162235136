<template>
  <div class="header__mobile">
    <div class="header__main row">
      <div class="column">
        <ul>
          <li>
            <button @click="openHeaderMenuExpand('mobile-nav')">
              <SvgIcon name="hamburger" title="Menu" />
            </button>
          </li>
          <li>
            <button @click="openHeaderMenuExpand('search')">
              <SvgIcon name="search" title="Search" />
            </button>
          </li>
        </ul>
      </div>
      <div class="column">
        <ul>
          <li><Account /></li>
          <li>
            <button @click="openHeaderMenuExpand('cart')">
              <span class="cart-items">{{ numCartItems }} <span class="accessible">items in bag</span></span><SvgIcon name="bag" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';

  import Account from '@/components/header/Account';
  import SvgIcon from '@/components/global/SvgIcon';

  export default {
    name: 'Desktop',

    components: {
      Account,
      SvgIcon
    },

    computed: {
      ...mapState({
        cartItems: state => state.cart.items
      }),
      ...mapGetters([
        'numCartItems'
      ]),
    },

    methods: {
      ...mapActions([
        'openHeaderMenuExpand'
      ])
    }
  }
</script>
